import { Grid } from "@mui/material";
import { useMediaQuery } from "@uidotdev/usehooks";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useState } from "react";
import Lottie from "react-lottie";
import { useDispatch } from "react-redux";
import resumeUpload from "../../../../assets/lotties/job-list/resume-uploading.json";
import { convertBase64, setItem } from "../../../../helpers/data_management";
import { sendTrackingEvent } from "../../../../helpers/tracking_management";
import {
  updateHomepageReplaceResumeDialog,
  updateHomepageUploadedResumeDialog,
} from "../../../../redux/actions/dialog_action";
import { updateSignUpOrigin } from "../../../../redux/actions/navbar_action";
import {
  checkAuthentication,
  getUser,
  newResumeUpload,
} from "../../../../redux/actions/user_action";
import * as types from "../../../../redux/types/user_type";
import {
  CTAContainer,
  ErrorIcon,
  ErrorMessage,
  ErrorMessageContainer,
  Filename,
  FileTypeText,
  Progress,
  ResumeContainer,
  StyledContent,
  StyledContentContainer,
  StyledDocumentIcon,
  StyledLI,
  StyledLinearProgress,
  StyledTopTitle,
  StyledUL,
  TextInstruction,
  ToastContentContainer,
  ToastContentText,
  ToastErrorIcon,
  ToastTitle,
  ToastTitleContainer,
  TopSectionContainer,
  UploadButton,
  UploadingResumeContainer,
  UploadingResumeContent,
  UploadingTextContainer,
} from "./styles";

export default function HomepageDropResume(props) {
  const { triggerToast, setUserResume, inputFileRef } = props;

  const dispatch = useDispatch();

  const router = useRouter();

  const screenDesktop = useMediaQuery("(min-width:1280px)");
  const screenTablet = useMediaQuery("(min-width:768px)");

  const loggedIn = checkAuthentication();

  const [error, setError] = useState(false);
  const [filename, setFilename] = useState("");
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dragFile, setDragFile] = useState(null);

  const user = getUser();

  function resumeValidation(file) {
    if (loggedIn) {
      if (!isEmpty(user?.resume)) {
        sendTrackingEvent({
          event: "CE_resume_upload_reupload",
          origin: "drop_resume_homepage",
          first_time: false,
          is_visitor: false,
        });
        dispatch(updateHomepageReplaceResumeDialog(true));
      } else {
        sendTrackingEvent({
          event: "CE_resume_upload_first_time",
          origin: "drop_resume_homepage",
          first_time: true,
          is_visitor: !loggedIn,
        });
        handleSubmitResume(file);
      }
    } else {
      sendTrackingEvent({
        event: "CE_resume_upload_first_time",
        origin: "drop_resume_homepage",
        first_time: true,
        is_visitor: !loggedIn,
      });
      dispatch(updateHomepageUploadedResumeDialog(true));
    }
  }

  function handleUploadButton() {
    const renderTimeout = setTimeout(() => {
      if (inputFileRef.current) {
        inputFileRef.current.click();
      }
    }, 250);

    return () => clearTimeout(renderTimeout);
  }

  async function handleFileChange(event) {
    event?.preventDefault();
    const file = event?.target?.files ? event?.target?.files[0] : dragFile;

    if (file) {
      if (file?.size <= 2048000) {
        try {
          setFilename(file?.name);
          setLoading(true);
          await uploadFileWithProgress(file);
          setError(false);
        } catch (error) {
          setError(true);
          setLoading(false);
          setProgress(0);
          setFilename("");
        } finally {
          setProgress(0);
          setLoading(false);
          setUserResume(file);
          resumeValidation(file);
          dispatch(updateSignUpOrigin("homepage-drop-resume"));
        }
      } else {
        triggerToast(
          false,
          <Grid>
            <ToastTitleContainer>
              <ToastErrorIcon />
              <ToastTitle>Failure to upload resume</ToastTitle>
            </ToastTitleContainer>
            <ToastContentContainer>
              <ToastContentText>Ensure that:</ToastContentText>
              <StyledUL>
                <StyledLI>
                  <ToastContentText>file size is below 2MB</ToastContentText>
                </StyledLI>
                <StyledLI>
                  <ToastContentText>
                    resume is in the correct format
                  </ToastContentText>
                </StyledLI>
              </StyledUL>
            </ToastContentContainer>
          </Grid>
        );
        setError(true);
      }
    }
  }

  function uploadFileWithProgress(file) {
    return new Promise((resolve) => {
      const fileSize = file?.size;
      let progress = 0;
      const interval = setInterval(() => {
        progress += 5;
        setProgress(progress);

        if (progress > 100) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  }

  async function handleSubmitResume(userResume) {
    if (userResume) {
      convertBase64(userResume).then((cvFile) => {
        // Trigger graphQL to upload cvFile
        let params = {
          resume: cvFile,
          resume_drop: true,
          canCancel: false,
          user_resume: "",
          origin: "drop_resume_homepage",
          first_time_upload_with_resume: true, // To determine if the user has already uploaded resume on onboarding/profile page and decide to submit resume via drop resume page
          autoGenerated: false,
          is_visitor: !loggedIn,
        };

        dispatch(newResumeUpload(params)).then((response) => {
          if (response.type === types.UPDATE_USER_CV_SUCCEED) {
            setItem("HOMEPAGE-RESUME-UPLOADED", true);
            setItem(types.USER_PROFILE, JSON.stringify(response.user));
            router.push("/jobs");
          } else {
            // toast(
            //   response.payload?.message
            //     ? response.payload.message
            //     : "Failed to update resume, please try again later"
            // );
            return;
          }
        });
      });
    }
  }

  function handleDragFile(event) {
    event.preventDefault();
  }

  function handleDrop(event) {
    event.preventDefault();
    setDragFile(event?.dataTransfer.files[0]);
    handleFileChange();
  }

  return (
    <ResumeContainer>
      <Grid>
        <TopSectionContainer>
          <StyledTopTitle>
            Upload Your CV for Instant Job Matches!
          </StyledTopTitle>
        </TopSectionContainer>
      </Grid>
      <Grid
        sx={{
          background: "#F5F5F5",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "0 0 8px 8px",
          padding: "20px 0",
        }}
        onDragOver={handleDragFile}
        onDrop={handleDrop}
      >
        <StyledContentContainer>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: resumeUpload,
            }}
            width={screenTablet ? "100px" : "64px"}
            height={screenTablet ? "100px" : "64px"}
          />
          <StyledContent error={error}>
            {error && (
              <ErrorMessageContainer>
                <ErrorIcon />
                <ErrorMessage>
                  File is too large. Maximum file size is 2MB.
                </ErrorMessage>
              </ErrorMessageContainer>
            )}
            <Grid
              display={"flex"}
              flexDirection={"column"}
              gap={screenTablet ? "6px" : "4px"}
            >
              {loading ? (
                <UploadingResumeContainer>
                  <Grid>
                    <StyledDocumentIcon />
                  </Grid>
                  <UploadingResumeContent>
                    <UploadingTextContainer>
                      <Filename>{filename}</Filename>
                      <Progress>{progress}%</Progress>
                    </UploadingTextContainer>
                    <StyledLinearProgress
                      variant="determinate"
                      value={progress}
                    />
                  </UploadingResumeContent>
                </UploadingResumeContainer>
              ) : (
                <CTAContainer>
                  {screenDesktop && !error && (
                    <Grid display={"flex"}>
                      <TextInstruction sx={{ fontWeight: 600 }}>
                        Drag & drop your resume&nbsp;
                      </TextInstruction>
                      <TextInstruction sx={{ fontWeight: 400 }}>
                        or
                      </TextInstruction>
                    </Grid>
                  )}
                  <UploadButton onClick={handleUploadButton}>
                    Upload Resume
                  </UploadButton>
                  <input
                    style={{ display: "none" }}
                    ref={inputFileRef}
                    accept=".pdf,.docx,.doc"
                    type="file"
                    onChange={handleFileChange}
                  />
                </CTAContainer>
              )}
              <FileTypeText>
                Support file type: .pdf, doc, .docx (2MB max)
              </FileTypeText>
            </Grid>
          </StyledContent>
        </StyledContentContainer>
      </Grid>
    </ResumeContainer>
  );
}
