import { Grid } from "@mui/material";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { convertBase64, getItem, setItem } from "../../../helpers/data_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import { updateHomepageReplaceResumeDialog } from "../../../redux/actions/dialog_action";
import { newResumeUpload } from "../../../redux/actions/user_action";
import * as types from "../../../redux/types/user_type";
import {
  CancelButton,
  CloseIcon,
  ContentContainer,
  ContentText,
  DialogContent,
  DialogFooter,
  DialogHeader,
  ExclaimationIcon,
  HeaderContainer,
  ReplaceButton,
  StyledDialog,
  StyledErrorCircle,
  StyledTitle,
  TitleContainer,
  ToastContentContainer,
  ToastContentText,
  ToastTitle,
  ToastTitleContainer,
} from "./styles";

export default function HomepageDropResume(props) {
  const { userResume, setUserResume, triggerToast } = props;

  const router = useRouter();

  const dispatch = useDispatch();

  const dialogStatus = useSelector(
    (state) => state.dialog?.showHomepageReplaceResumeDialog
  );

  function handleClose() {
    dispatch(updateHomepageReplaceResumeDialog(false));
    router.push("/jobs");
  }

  function handleReplace(userResume) {
    sendTrackingEvent({
      event: "CE_resume_replace",
      origin: "drop_resume_homepage",
    });
    handleSubmitResume(userResume);
  }

  async function handleSubmitResume(userResume) {
    if (userResume) {
      convertBase64(userResume).then((cvFile) => {
        // Trigger graphQL to upload cvFile
        let params = {
          resume: cvFile,
          resume_drop: true,
          canCancel: false,
          user_resume: "",
          origin: "homepage",
          first_time_upload_with_resume: true, // To determine if the user has already uploaded resume on onboarding/profile page and decide to submit resume via drop resume page
          autoGenerated: false,
        };

        dispatch(newResumeUpload(params)).then((response) => {
          if (response.type === types.UPDATE_USER_CV_SUCCEED) {
            setItem("HOMEPAGE-RESUME-UPLOADED", true);
            setItem(types.USER_PROFILE, JSON.stringify(response.user));
            handleClose();
          } else {
            triggerToast(
              false,
              toastContent("Failed to update resume, please try again later.")
            );
          }
        });
      });
    } else {
      const localStorageTempResume = JSON.parse(getItem("TEMP_RESUME_BASE64"));

      let params = {
        resume: localStorageTempResume,
        resume_drop: true,
        canCancel: false,
        user_resume: "",
        origin: "homepage",
        first_time_upload_with_resume: true, // To determine if the user has already uploaded resume on onboarding/profile page and decide to submit resume via drop resume page
        autoGenerated: false,
      };

      dispatch(newResumeUpload(params)).then((response) => {
        if (response.type === types.UPDATE_USER_CV_SUCCEED) {
          localStorage.removeItem("TEMP_RESUME_BASE64");
          setItem("HOMEPAGE-RESUME-UPLOADED", true);
          setItem(types.USER_PROFILE, JSON.stringify(response.user));
          handleClose();
        } else {
          triggerToast(
            false,
            toastContent("Failed to update resume, please try again later.")
          );
        }
      });
    }
  }

  function toastContent(message) {
    return (
      <Grid>
        <ToastTitleContainer>
          <StyledErrorCircle />
          <ToastTitle>Error</ToastTitle>
        </ToastTitleContainer>
        <ToastContentContainer>
          <ToastContentText>{message}</ToastContentText>
        </ToastContentContainer>
      </Grid>
    );
  }

  return (
    <StyledDialog open={dialogStatus}>
      <DialogHeader>
        <HeaderContainer>
          <TitleContainer>
            <ExclaimationIcon />
            <StyledTitle>You have existing resume.</StyledTitle>
          </TitleContainer>
          <CloseIcon
            onClick={() => {
              dispatch(updateHomepageReplaceResumeDialog(false));
            }}
          />
        </HeaderContainer>
      </DialogHeader>
      <DialogContent>
        <ContentContainer>
          <ContentText>
            We noticed you already have a resume uploaded. Would you like to
            replace the existing resume on your user profile?
          </ContentText>
        </ContentContainer>
      </DialogContent>
      <DialogFooter>
        <CancelButton onClick={handleClose}>Cancel</CancelButton>
        <ReplaceButton
          onClick={() => {
            handleReplace(userResume);
          }}
        >
          Replace Resume
        </ReplaceButton>
      </DialogFooter>
    </StyledDialog>
  );
}
