import ExclamationCircleIcon16 from "@heroicons/react/16/solid/ExclamationCircleIcon";
import DocumentIcon from "@heroicons/react/24/outline/DocumentIcon";
import ExclamationCircleIcon from "@heroicons/react/24/outline/ExclamationCircleIcon";
import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ResumeContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItem: "center",
  borderRadius: "8px",
  border: "1px dashed #512ACC",

  width: "100%",
  height: "100%",
  maxHeight: "203px",

  "@media(max-width: 767.9px)": {
    minHeight: "fit-content",
    maxHeight: "180px",
  },
});

export const TopSectionContainer = styled(Grid)({
  display: "flex",
  padding: "6px 8px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  alignSelf: "stretch",
  borderBottom: "1px dashed #512ACC",
  background: "#EFEFFD",

  borderRadius: "8px 8px 0 0",
});

export const StyledTopTitle = styled(Typography)({
  color: "#40249B",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "20px",
  letterSpacing: "0.112px",
});

export const StyledContentContainer = styled(Grid)({
  display: "flex",
  paddingRight: "24px",
  justifyContent: "center",
  alignItems: "center",
  gap: "24px",

  "@media(max-width: 767.9px)": {
    flexDirection: "column",
    paddingRight: "0",
    gap: "4px",
  },
});

export const StyledContent = styled(Grid)(({ error }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: !error ? "10px" : "6px",

  "@media(max-width: 767.9px)": {
    gap: !error ? "10px" : "4px",
  },
}));

export const CTAContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "6px",
});

export const TextInstruction = styled(Typography)({
  color: "#00000099",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  lineHeight: "24px",
  letterSpacing: "0.1px",
});

export const UploadButton = styled(Button)({
  borderRadius: "8px",
  border: "2px solid #000",
  background: "#31C47A",

  textTransform: "none",
  color: "#FFFFFF",

  ":hover": {
    background: "#37B074",
  },
});

export const FileTypeText = styled(Typography)({
  color: "#00000099",
  fontFamily: "Inter",
  fontSize: "10px",
  fontStyle: "italic",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0.15px",

  textAlign: "center",
});

export const UploadingResumeContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
  gap: "6px",
  flex: "1 0 0",
  width: "100%",
  maxWidth: "240px",
});

export const StyledDocumentIcon = styled(DocumentIcon)({
  color: "#00000099",
  width: "24px",
  height: "24px",
});

export const UploadingResumeContent = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "6px",
  flex: "1 0 0",
});

export const UploadingTextContainer = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  alignSelf: "stretch",
  maxWidth: "210px",
});

export const Filename = styled(Typography)({
  color: "#00000099",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0.15px",

  textWrap: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: "85%",
});

export const Progress = styled(Typography)({
  color: "#40249B",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0.15px",
});

export const StyledLinearProgress = styled(LinearProgress)({
  width: "210px",
  alignSelf: "stretch",
  borderRadius: "100px",
  background: "#D8D8D8",
});

export const ErrorMessageContainer = styled(Grid)({
  display: "flex",
  height: "100%",
  maxHeight: "32px",
  padding: "4px 6px",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px",
  borderRadius: "8px",
  border: "1px solid #D87474",
  background: "rgba(216, 116, 116, 0.10)",
});

export const ErrorMessage = styled(Grid)({
  color: "#000000DE",
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "italic",
  fontWeight: 400,
  lineHeight: "20px",
});

export const ErrorIcon = styled(ExclamationCircleIcon)({
  width: "100%",
  height: "100%",
  maxWidth: "18px",
  maxHeight: "18px",
  color: "#D87474",
});

export const ToastTitleContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

export const ToastTitle = styled(Typography)({
  color: "#000000",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "22px",
});

export const ToastErrorIcon = styled(ExclamationCircleIcon16)({
  width: "15px",
  height: "15px",
  color: "#F75443",
});

export const ToastContentContainer = styled(Grid)({
  marginTop: "8px",
});

export const ToastContentText = styled(Typography)({
  color: "#00000099",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
});

export const StyledUL = styled("ul")({
  margin: 0,
  paddingInlineStart: "25px",
});

export const StyledLI = styled("li")({
  fontSize: "15px",
  color: "#00000099",
});
