import ExclamationCircleIcon from "@heroicons/react/20/solid/ExclamationCircleIcon";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import SolidExclamationCircleIcon from "@heroicons/react/24/solid/ExclamationCircleIcon";
import { Button, Dialog, Grid, Typography } from "@mui/material";
import { styled } from "@mui/styles";

export const StyledDialog = styled(Dialog)({
  "& .MuiDialog-container .MuiPaper-root": {
    display: "flex",
    width: "100%",
    height: "100%",
    maxWidth: "400px",
    maxHeight: "240px",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "16px",
    border: "1.5px solid var(--Primary-Black-One, #222)",
    background: "var(--Primary-White, #FFF)",
    minWidth: "328px",

    "&:hover": {
      boxShadow: "2px 4px 10px 0px rgba(0, 0, 0, 0.12)",
    },
  },
});

export const DialogHeader = styled(Grid)({
  "&&": {
    display: "flex",
    padding: "12px 12px 8px 12px",
    flexDirection: "column",
    alignItems: "center",
    gap: "2px",
    alignSelf: "stretch",
    borderBottom: "1px solid #D8D8D8",
    background: "#FFFFFF",
  },
});

export const HeaderContainer = styled(Grid)({
  "&&": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    alignSelf: "stretch",
  },
});

export const TitleContainer = styled(Grid)({
  "&&": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    flex: "1 0 0",
    alignSelf: "stretch",
  },
});

export const ExclaimationIcon = styled(ExclamationCircleIcon)({
  width: "18px",
  height: "18px",
  color: "#F2A647",
});

export const StyledTitle = styled(Typography)({
  "&&": {
    color: "#000000DE",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0.1px",
  },
});

export const CloseIcon = styled(XMarkIcon)({
  width: "24px",
  height: "24px",
  cursor: "pointer",
});

export const DialogContent = styled(Grid)({
  "&&": {
    display: "flex",
    padding: "8px 16px 16px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    flex: "1 0 0",
    alignSelf: "stretch",
  },
});

export const ContentContainer = styled(Grid)({
  "&&": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 0 0",
    alignSelf: "stretch",

    height: "100%",
    width: "100%",
  },
});

export const ContentText = styled(Typography)({
  "&&": {
    color: "#000000DE",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.112px",

    height: "fit-content",
  },
});

export const DialogFooter = styled(Grid)({
  "&&": {
    display: "flex",
    padding: "12px 16px",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    borderTop: "1px solid #D8D8D8",
    background: "#FFFFFF",
  },
});

export const CancelButton = styled(Button)({
  "&&": {
    display: "flex",
    height: "36px",
    padding: "0px 20px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "100px",
    background: "transparent",
    color: "#000000DE",

    textTransform: "none",

    "&:hover": {
      background: "transparent",
      textDecoration: "underline",
    },
  },
});

export const ReplaceButton = styled(Button)({
  "&&": {
    display: "flex",
    height: "36px",
    padding: "0px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "100px",
    background: "#353535",
    color: "#FFFFFF",

    textTransform: "none",

    "&:hover": {
      background: "#222222",
    },
  },
});

// Toast styles

export const ToastTitleContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

export const ToastTitle = styled(Typography)({
  color: "#000000",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "22px",
});

export const StyledErrorCircle = styled(SolidExclamationCircleIcon)({
  width: "15px",
  height: "15px",
  color: "#F75443",
});

export const ToastContentContainer = styled(Grid)({
  marginTop: "8px",
});

export const ToastContentText = styled(Typography)({
  color: "#00000099",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
});
