import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadFileToS3 } from "../../../helpers/update_s3";
import { updateHomepageViewResumeDialog } from "../../../redux/actions/dialog_action";
import { StyledDialog } from "../HomepageViewResumeDialog/styles";

export default function HomepageViewResumeDialog(props) {
  const { userResume } = props;

  const dispatch = useDispatch();

  const dialogStatus = useSelector(
    (state) => state?.dialog?.showHomepageViewResumeDialog
  );

  const [pdfUrl, setPdfUrl] = useState(null);

  function handleClose() {
    dispatch(updateHomepageViewResumeDialog(false));
    setPdfUrl(null);
  }

  useEffect(() => {
    async function handleTempUpload() {
      const tempS3File = await uploadFileToS3(userResume);

      if (
        userResume &&
        (userResume instanceof Blob || resume instanceof File)
      ) {
        try {
          if (tempS3File) {
            if (/(\.docx|\.doc)$/i.test(tempS3File)) {
              setPdfUrl(
                `https://view.officeapps.live.com/op/embed.aspx?src=${tempS3File}`
              );
            } else {
              setPdfUrl(
                `https://drive.google.com/viewerng/viewer?embedded=true&url=${tempS3File}`
              );
            }
          } else {
            const fileURL = URL.createObjectURL(userResume);
            setPdfUrl(fileURL);
          }
        } catch (error) {
          console.error("Error uploading file: ", error);
        }
      }
    }
    handleTempUpload();
  }, [dialogStatus]);

  return (
    <StyledDialog open={dialogStatus}>
      {pdfUrl ? (
        <iframe
          src={pdfUrl}
          title="PDF Viewer"
          height={"100%"}
          width={"100%"}
          paddingBottom={"24px"}
        />
      ) : (
        <CircularProgress />
      )}
      <div></div>
      <Grid
        sx={{
          position: "absolute",
          bottom: 0,
          cursor: "pointer",
          width: "100%",
          height: "24px",
          textAlign: "center",
          background: "#FFF",
        }}
        onClick={handleClose}
      >
        Close
      </Grid>
    </StyledDialog>
  );
}
