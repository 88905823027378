import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { jobCategoryFilters } from "../../../helpers/constant";
import { updateRecentSearches } from "../../../helpers/data_management";
import { updateJobListSearchKeyword } from "../../../redux/actions/job_action";
import { store } from "../../../redux/stores/store";
import Button from "../../shared/SharedButton/SharedButton";
import {
  CategoryBox,
  CategoryBoxItem,
  CategoryIconGrid,
  CategoryTitle,
  ChipStyled,
  ChipsContainer,
  LinkStyled,
  SearchIconStyled,
  TitleStyled,
  TrendingSearchesContainer,
  TrendingSearchesOuterWrapper,
} from "./styles";

function HomepageTrendingSearches(props) {
  const dispatch = useDispatch();
  const router = useRouter();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    // Compare once and add resize listener on "componentDidMount"
    window.addEventListener("resize", compareSize);

    if (!width) {
      setWidth(window.innerWidth);
    }

    // Remove resize listener again on "componentWillUnmount"
    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);

  const compareSize = () => {
    setWidth(window.innerWidth);
  };

  function handleChipClicked(event, keyword) {
    event.stopPropagation();
    event.preventDefault();
    store.getState().jobs.jobListFilterLanding["keyword"] = keyword;
    updateRecentSearches(keyword);
    store.getState().jobs.fromLandingPage = true;
    store.getState().jobs.fromHomepageTrendingSearches = true;
    router.push("/jobs");
  }

  function handleCategoryFilterClick(category) {
    if (category.id !== 7) {
      dispatch(updateJobListSearchKeyword({ keyword: "" }));
    } else {
      dispatch(updateJobListSearchKeyword({ keyword: "remote" }));
    }

    router.push(category.url);
  }

  return (
    <TrendingSearchesOuterWrapper>
      <TrendingSearchesContainer>
        <TitleStyled>
          {width >= 768 ? "Trending Job Categories" : "Trending Searches"}
        </TitleStyled>
        <ChipsContainer>
          {width >= 768 ? (
            <Fragment>
              {jobCategoryFilters.map((jobCategoryFilter, index) => {
                return (
                  <CategoryBox
                    display="grid"
                    gridTemplateColumns="repeat(2, auto)"
                    key={jobCategoryFilter.id}
                    onClick={() => handleCategoryFilterClick(jobCategoryFilter)}
                    id={jobCategoryFilter.element_id}
                  >
                    <CategoryBoxItem>
                      <CategoryIconGrid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        {jobCategoryFilter.icon}
                      </CategoryIconGrid>
                    </CategoryBoxItem>
                    <CategoryBoxItem>
                      <CategoryTitle variant="subtitle1">
                        {jobCategoryFilter.title}
                      </CategoryTitle>
                    </CategoryBoxItem>
                  </CategoryBox>
                );
              })}
            </Fragment>
          ) : (
            <Fragment>
              {props.trendingJobKeywords.slice(0, 5).map((keyword, index) => {
                return (
                  <ChipStyled
                    icon={<SearchIconStyled />}
                    label={keyword}
                    key={index}
                    onClick={(event) => handleChipClicked(event, keyword)}
                  />
                );
              })}
            </Fragment>
          )}
        </ChipsContainer>
        <LinkStyled href={"/jobs"}>
          <Button
            id={"homepage-view-more-jobs-button"}
            button_type={"OutlinedTransparentIndigo"}
          >
            View More Jobs
          </Button>
        </LinkStyled>
      </TrendingSearchesContainer>
    </TrendingSearchesOuterWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    jobListFilterLanding: state.jobs.jobListFilterLanding,
    trendingJobKeywords: state.jobs.trendingJobKeywords,
  };
};

export default connect(mapStateToProps, null)(HomepageTrendingSearches);
