import { Grid, Toolbar } from "@mui/material";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { Fragment, useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bindActionCreators } from "redux";
import { fetchRecentSearches, getItem } from "../../../helpers/data_management";
import { updateProfilePreviewDialog } from "../../../redux/actions/dialog_action";
import {
  updateShowSignInModalStatus,
  updateSignInModalSignUpStatus,
  updateSignInText,
  updateSignUpText,
} from "../../../redux/actions/navbar_action";
import {
  checkAuthentication,
  getUser,
} from "../../../redux/actions/user_action";
import { store } from "../../../redux/stores/store";
import SharedDownloadAppBanner from "../../shared/SharedDownloadAppBanner/SharedDownloadAppBanner";
import SharedNavbar2 from "../../shared/SharedNavbar/Navbar/Navbar";
import HomepageAds from "../HomepageAds/HomepageAds";
import HomepageDiscoverCompanies from "../HomepageDiscoverCompanies/HomepageDiscoverCompanies";
import HomepageReplaceResumeDialog from "../HomepageReplaceResumeDialog/HomepageReplaceResumeDialog";
import HomepageResumeUploadedDialog from "../HomepageResumeUploadedDialog/HomepageResumeUploadedDialog";
import HomepageSearch from "../HomepageSearch/HomepageSearch";
import HomepageTrendingSearches from "../HomepageTrendingSearches/HomepageTrendingSearches";
import HomepageViewResumeDialog from "../HomepageViewResumeDialog/HomepageViewResumeDialog";
import MobileHomepageSearch from "../MobileHomepageSearch/MobileHomepageSearch";
import {
  BannerHomepageWrapper,
  StyledCheckCircle,
  ToastContentContainer,
  ToastContentText,
  ToastStyled,
  ToastTitle,
  ToastTitleContainer,
} from "./styles";

function HomepageWrapper(props) {
  const [transparentNavbar, setTransparentNavbar] = useState(false);
  const [width, setWidth] = useState(0);
  const [userResume, setUserResume] = useState(null);
  const [toastBool, setToastBool] = useState(null);
  const inputFileRef = useRef(null);
  const user = getUser();
  const recentSearches = fetchRecentSearches();
  const router = useRouter();
  let urlParams;

  const alertBanner = useSelector((state) => state.dialog.showAlertBanner);

  useEffect(() => {
    const emailHotJob = getItem("email-hot-job");

    if (emailHotJob) {
      props.updateShowSignInModalStatus(true);
    }

    // Check for MRP sign-up in URL
    if (
      router.asPath.includes("referral_new_sign_up") &&
      !checkAuthentication()
    ) {
      const url = window.location.search;
      const urlParams = new URLSearchParams(url);
      const mrpParentID = urlParams.get("mrp_parent_id");
      const refereeEmail = urlParams.get("referee_email");

      store.getState().user.user.email = refereeEmail;
      props.updateShowSignInModalStatus(true);
      props.updateSignInModalSignUpStatus(true);

      if (mrpParentID?.length > 0 && refereeEmail?.length > 0) {
        Cookies.set("mrpId", mrpParentID, { expires: 7 });
      } else if (document.cookie.indexOf("mrpId") != -1) {
        //
      }
    }

    // Check for October Follower Growth Campaign sign-up and pass url params to redirected users
    if (router.asPath.includes("october_follower_growth_campaign")) {
      props.updateShowSignInModalStatus(true);
      props.updateSignInModalSignUpStatus(true);
      urlParams = new URLSearchParams(window.location.search);
    }

    // Redirect logged-in users or visitors with search to jobs list page
    if (user || recentSearches.length > 0) {
      if (router.asPath.includes("october_follower_growth_campaign")) {
        router.push(`/jobs/?${urlParams}`);
      }
    }

    // Compare once and add resize listener on "componentDidMount"
    window.addEventListener("resize", compareSize);

    if (!width) {
      setWidth(window.innerWidth);
    }

    setTransparentNavbar(true);

    // Remove resize listener again on "componentWillUnmount"
    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);

  const compareSize = () => {
    setWidth(window.innerWidth);
  };

  const triggerToast = (bool, content) => {
    setToastBool(bool);

    const toastConfig = {
      icon: false,
      className: bool ? "Toast-success" : "Toast-error",
    };

    if (bool) {
      toast(toastUploadedResume(), toastConfig);
    } else {
      toast(content, toastConfig);
    }
  };

  function toastUploadedResume() {
    return (
      <Grid>
        <ToastTitleContainer>
          <StyledCheckCircle />
          <ToastTitle>Success</ToastTitle>
        </ToastTitleContainer>
        <ToastContentContainer>
          <ToastContentText>Resume updated successfully.</ToastContentText>
        </ToastContentContainer>
      </Grid>
    );
  }

  return (
    <Fragment>
      <HomepageResumeUploadedDialog
        inputFileRef={inputFileRef}
        triggerToast={triggerToast}
        userResume={userResume}
        setUserResume={setUserResume}
      />
      <HomepageReplaceResumeDialog
        triggerToast={triggerToast}
        userResume={userResume}
        setUserResume={setUserResume}
      />
      <HomepageViewResumeDialog userResume={userResume} />
      <ToastStyled
        position="top-right"
        autoClose={2000}
        limit={2}
        pauseOnHover={false}
      />
      {props.showMobileSearch ? (
        <MobileHomepageSearch />
      ) : (
        <Fragment>
          <SharedNavbar2 page="user-landing-page" />
          <Toolbar />
          <BannerHomepageWrapper $alertBanner={alertBanner}>
            <HomepageSearch
              inputFileRef={inputFileRef}
              triggerToast={triggerToast}
              userResume={userResume}
              setUserResume={setUserResume}
            />
            <HomepageAds />
            <HomepageTrendingSearches />
            <HomepageDiscoverCompanies />
          </BannerHomepageWrapper>
          {process.env.NEXT_PUBLIC_JSW_GEOLOCATION !== "sg" && (
            <SharedDownloadAppBanner />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    showMobileSearch: state.navbar.showMobileSearch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateShowSignInModalStatus: bindActionCreators(
      updateShowSignInModalStatus,
      dispatch
    ),
    updateSignInText: bindActionCreators(updateSignInText, dispatch),
    updateSignUpText: bindActionCreators(updateSignUpText, dispatch),
    updateSignInModalSignUpStatus: bindActionCreators(
      updateSignInModalSignUpStatus,
      dispatch
    ),
    updateProfilePreviewDialog: bindActionCreators(
      updateProfilePreviewDialog,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomepageWrapper);
