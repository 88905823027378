import Fade from "@mui/material/Fade";
import useMediaQuery from "@mui/material/useMediaQuery";
import Cookies from "js-cookie";
import { Fragment, useEffect, useState } from "react";
import * as config from "../../../../config/config";
import { sendTrackingEvent } from "../../../../helpers/tracking_management";
import {
  BlankGrid,
  CustomTooltip,
  DesktopImageContainer,
  ImageContainer,
  ImageStyled,
  LinkStyled,
} from "./styles";

function ImageWithTooltip(props) {
  const { id, imageList, imagePlaceholder, opacity, transform } = props;
  const [hasError, setHasError] = useState(false);

  const handleError = () => {
    setHasError(true);
  };

  // Detect Screen Width
  const screenDesktop = useMediaQuery("(min-width:1280px)");

  const timeout = [200, 1000, 3000, 1800, 3400, 3800, 600, 1400, 2600];

  // GTM tracking custom event for tracking clicks of company logos on homepage
  const handleClickCompany = (companyId) => {
    sendTrackingEvent({
      event: "CE_click-company-icon-homepage",
      "company-id": companyId,
    });

    Cookies.set("click-source", "company_icon");
  };

  let clickableLink = `/companies/${imageList?.node?.company?.slug?.trim()}`;

  if (clickableLink.endsWith("-")) {
    clickableLink = clickableLink.slice(0, -1);
  }

  return (
    <Fade in={true} timeout={timeout[id]}>
      <BlankGrid>
        <CustomTooltip
          followCursor
          title={!hasError && imageList?.node?.companyName}
        >
          <LinkStyled
            href={clickableLink}
            aria-disabled={hasError || !imageList}
            rel="noopener noreferrer"
            target="_blank"
          >
            <ImageContainer
              onClick={() => handleClickCompany(imageList?.node?.companyId)}
              container
              justifyContent="center"
              alignItems="center"
              opacity={screenDesktop ? 1 : opacity}
              transform={screenDesktop ? "" : transform}
            >
              {imageList && !hasError ? (
                <ImageStyled
                  src={"https:" + `${imageList?.node?.logo}`}
                  alt={"https:" + `${imageList?.node?.order}`}
                  onError={handleError}
                />
              ) : (
                <ImageStyled
                  src={config.assetDomain + imagePlaceholder}
                  alt="company logo placeholder"
                />
              )}
            </ImageContainer>
          </LinkStyled>
        </CustomTooltip>
      </BlankGrid>
    </Fade>
  );
}

function HomepageLogo(props) {
  const { imageList, isSecond, screenTablet } = props;

  const imagePlaceholder = [
    "/images/homepage-logo-placeholder/icon_internship.webp",
    "/images/homepage-logo-placeholder/icon_court.webp",
    "/images/homepage-logo-placeholder/icon_shop.webp",
    "/images/homepage-logo-placeholder/icon_fire.webp",
    "/images/homepage-logo-placeholder/icon_medical.webp",
    "/images/homepage-logo-placeholder/icon_company.webp",
    "/images/homepage-logo-placeholder/icon_hiredly_logo.webp",
    "/images/homepage-logo-placeholder/icon_puzzle.webp",
    "/images/homepage-logo-placeholder/icon_code.webp",
  ];

  // Pulsing sequence
  const sequence = [3, 1, 4, 0, 2];
  const sequence2 = [0, 3, 2, 1];
  const [pointer, setPointer] = useState(0);
  const [pointer2, setPointer2] = useState(0);

  // For the pulsing animation 1
  const [time, setTime] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(time + 1);

      if (pointer === 4) {
        setPointer(0);
      } else {
        setPointer(pointer + 1);
      }
    }, 1700);
    return () => {
      clearTimeout(timer);
    };
  }, [time, pointer]);

  // For the pulsing animation 2
  const [time2, setTime2] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setTime2(time2 + 1);

      if (pointer2 === 3) {
        setPointer2(0);
      } else {
        setPointer2(pointer2 + 1);
      }
    }, 1400);
    return () => {
      clearTimeout(timer);
    };
  }, [time2, pointer2]);

  return (
    <Fragment>
      {/* First */}
      {/* If under 1280px viewport */}
      {screenTablet ? (
        <DesktopImageContainer container direction="column">
          <ImageWithTooltip
            id={0}
            imageList={imageList[0]}
            imagePlaceholder={imagePlaceholder[0]}
            opacity={sequence[pointer] === 0 ? 1 : 0.5}
            transform={sequence[pointer] === 0 ? `scale(1.2)` : ""}
          />
        </DesktopImageContainer>
      ) : (
        <DesktopImageContainer
          container
          direction="column"
          marginTop={!isSecond && "120px"}
        >
          <ImageWithTooltip
            id={0}
            imageList={imageList[0]}
            imagePlaceholder={imagePlaceholder[0]}
            opacity={sequence[pointer] === 0 ? 1 : 0.5}
            transform={sequence[pointer] === 0 ? `scale(1.2)` : ""}
          />
          <ImageWithTooltip
            id={1}
            imageList={imageList[1]}
            imagePlaceholder={imagePlaceholder[1]}
            opacity={sequence[pointer] === 1 ? 1 : 0.5}
            transform={sequence[pointer] === 1 ? `scale(1.2)` : ""}
          />
          {/* Show the third logo from the SECOND image container before title*/}
          {isSecond && (
            <ImageWithTooltip
              id={2}
              imageList={imageList[2]}
              imagePlaceholder={imagePlaceholder[2]}
              opacity={sequence[pointer] === 2 ? 1 : 0.5}
              transform={sequence[pointer] === 2 ? `scale(1.2)` : ""}
            />
          )}
        </DesktopImageContainer>
      )}
      {/* Second */}
      {/* If under 1280px viewport */}
      {screenTablet ? (
        <DesktopImageContainer container direction="column">
          <ImageWithTooltip
            id={1}
            imageList={imageList[1]}
            imagePlaceholder={imagePlaceholder[1]}
            opacity={sequence[pointer] === 1 ? 1 : 0.5}
            transform={sequence[pointer] === 1 ? `scale(1.2)` : ""}
          />
          <ImageWithTooltip
            id={2}
            imageList={imageList[2]}
            imagePlaceholder={imagePlaceholder[2]}
            opacity={sequence[pointer] === 2 ? 1 : 0.5}
            transform={sequence[pointer] === 2 ? `scale(1.2)` : ""}
          />
        </DesktopImageContainer>
      ) : (
        <DesktopImageContainer
          container
          direction="column"
          marginBottom={!isSecond && "50px"}
        >
          {/* Show the third logo from FIRST image container before title*/}
          {!isSecond && (
            <ImageWithTooltip
              id={2}
              imageList={imageList[2]}
              imagePlaceholder={imagePlaceholder[2]}
              opacity={sequence[pointer] === 2 ? 1 : 0.5}
              transform={sequence[pointer] === 2 ? `scale(1.2)` : ""}
            />
          )}
          <ImageWithTooltip
            id={3}
            imageList={imageList[3]}
            imagePlaceholder={imagePlaceholder[3]}
            opacity={sequence[pointer] === 3 ? 1 : 0.5}
            transform={sequence[pointer] === 3 ? `scale(1.2)` : ""}
          />
          {/* Show the fifth logo from second image container after title */}
          {isSecond && (
            <ImageWithTooltip
              id={4}
              imageList={imageList[4]}
              imagePlaceholder={imagePlaceholder[4]}
              opacity={sequence[pointer] === 4 ? 1 : 0.5}
              transform={sequence[pointer] === 4 ? `scale(1.2)` : ""}
            />
          )}
        </DesktopImageContainer>
      )}
      {/* Third (if under 1280px viewport)*/}
      {screenTablet && (
        <DesktopImageContainer container direction="column" marginTop="120px">
          <ImageWithTooltip
            id={3}
            imageList={imageList[3]}
            imagePlaceholder={imagePlaceholder[3]}
            opacity={sequence[pointer] === 3 ? 1 : 0.5}
            transform={sequence[pointer] === 3 ? `scale(1.2)` : ""}
          />
          <ImageWithTooltip
            id={4}
            imageList={imageList[4]}
            imagePlaceholder={imagePlaceholder[4]}
            opacity={sequence[pointer] === 4 ? 1 : 0.5}
            transform={sequence[pointer] === 4 ? `scale(1.2)` : ""}
          />
        </DesktopImageContainer>
      )}
      {/* Fourth (if under 1280px viewport)*/}
      {screenTablet && (
        <DesktopImageContainer container direction="column" marginTop="50px">
          <ImageWithTooltip
            id={5}
            imageList={imageList[5]}
            imagePlaceholder={imagePlaceholder[5]}
            opacity={sequence2[pointer2] === 0 ? 1 : 0.5}
            transform={sequence2[pointer2] === 0 ? `scale(1.2)` : ""}
          />
          <ImageWithTooltip
            id={6}
            imageList={imageList[6]}
            imagePlaceholder={imagePlaceholder[6]}
            opacity={sequence2[pointer2] === 1 ? 1 : 0.5}
            transform={sequence2[pointer2] === 1 ? `scale(1.2)` : ""}
          />
          <ImageWithTooltip
            id={7}
            imageList={imageList[7]}
            imagePlaceholder={imagePlaceholder[7]}
            opacity={sequence2[pointer2] === 2 ? 1 : 0.5}
            transform={sequence2[pointer2] === 2 ? `scale(1.2)` : ""}
          />
        </DesktopImageContainer>
      )}
      {/* Fifth (if under 1280px viewport)*/}
      {screenTablet && (
        <DesktopImageContainer container marginTop="160px">
          <ImageWithTooltip
            id={8}
            imageList={imageList[8]}
            imagePlaceholder={imagePlaceholder[8]}
            opacity={sequence2[pointer2] === 3 ? 1 : 0.5}
            transform={sequence2[pointer2] === 3 ? `scale(1.2)` : ""}
          />
        </DesktopImageContainer>
      )}
    </Fragment>
  );
}

export default HomepageLogo;
