import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import ExclamationCircleIcon from "@heroicons/react/24/outline/ExclamationCircleIcon";
import EyeIcon from "@heroicons/react/24/outline/EyeIcon";
import EyeSlashIcon from "@heroicons/react/24/outline/EyeSlashIcon";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon";
import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
import SolidExclamationCircleIcon from "@heroicons/react/24/solid/ExclamationCircleIcon";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";

export const StyledDialog = styled(Dialog)({
  "& .MuiDialog-container .MuiPaper-root": {
    display: "flex",
    width: "100%",
    maxWidth: "670px",
    height: "fit-content",
    flexDirection: "column",
    alignItems: "center",

    borderRadius: "16px",
    border: "1.5px solid var(--Primary-Black, #222)",
    background: "var(--Primary-White, #FFF)",

    "&:hover": {
      boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.16)",
    },

    "@media(max-width: 767.9px)": {
      minWidth: "328px",
    },
  },
});

export const StyledCloseIcon = styled(XMarkIcon)({
  width: "24px",
  height: "24px",
  cursor: "pointer",
});

export const DialogHeader = styled(Grid)({
  "&&": {
    display: "flex",
    padding: "16px 24px",
    flexDirection: "column",
    alignItems: "center",
    gap: "4px",
    alignSelf: "stretch",
    borderBottom: "1px solid var(--Grey-Grey, #D8D8D8)",
    background: "var(--Primary-White, #FFF)",
    width: "100%",

    "@media(max-width: 767.9px)": {
      padding: "12px 12px 8px 12px",
      gap: "2px",
    },
  },
});

export const TitleContainer = styled(Grid)({
  "&&": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    flex: "1 0 0",
    alignSelf: "stretch",
  },
});

export const StyledCheckCircleIcon = styled(CheckCircleIcon)({
  color: "#6EC197",
  width: "24px",
  height: "24px",

  "@media(max-width: 767.9px)": {
    width: "18px",
    height: "18px",
  },
});

export const StyledTitle = styled(Typography)({
  "&&": {
    color: "#000000DE",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",

    "@media(max-width: 767.9px)": {
      fontSize: "16px",
    },
  },
});

export const StyledTitleInfo = styled(Typography)(({ type }) => ({
  "&&": {
    display: "inline",

    color: "#000000DE",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.112px",

    ...(type == "login" && {
      color: "#512ACC",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "20pxpx",
      letterSpacing: "0.112px",
      textDecorationLine: "underline",
      textDecorationStyle: "solid",
      textDecorationSkipInk: "auto",
      textDecorationThickness: "auto",
      textUnderlineOffset: "auto",
      textUnderlinePosition: "from-font",

      cursor: "pointer",
    }),

    "@media(max-width: 767.9px)": {
      fontSize: "12px",
    },
  },
}));

export const DialogContent = styled(Grid)({
  "&&": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: "1 0 0",
    alignSelf: "stretch",
    width: "100%",
  },
});

export const DetailWrapper = styled(Grid)(({ isLogin }) => ({
  "&&": {
    display: "flex",
    alignItems: "flex-start",
    alignSelf: "stretch",
    padding: "16px 16px 0px 16px",
    borderBottom: !isLogin && "1px solid var(--Grey-Grey, #D8D8D8)",
    width: "100%",
  },
}));

export const DetailContainer = styled(Grid)({
  "&&": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingBottom: "16px",
    gap: "16px",
    flex: "1 0 0",
    width: "100%",
  },
});

export const DetailResumeContainer = styled(Grid)({
  "&&": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    alignSelf: "stretch",
  },
});

export const ContentTitle = styled(Typography)({
  "&&": {
    color: "#222",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
  },
});

export const ResumeWrapper = styled(Grid)({
  "&&": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "10px 12px",
    gap: "16px",
    alignSelf: "stretch",
    borderRadius: "10px",
    background: "var(--Background-Background-Grey, #F5F5F5)",

    width: "100%",
  },
});

export const ResumeContainer = styled(Grid)({
  "&&": {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    alignSelf: "stretch",
    borderRadius: "100px",

    "@media(max-width:767.9px)": {
      flexDirection: "column",
    },
  },
});

export const ResumeStatusContainer = styled(Grid)({
  "&&": {
    display: "flex",
    alignItems: "flex-start",
    gap: "8px",
    flex: "1 0 0",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "370px",

    "@media(max-width:767.9px)": {
      maxWidth: "none",
    },
  },
});

export const ResumeButtonContainer = styled(Grid)({
  "&&": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "8px",

    "@media(max-width: 767.9px)": {
      width: "100%",
    },
  },
});

export const ResumeFilename = styled(Typography)({
  "&&": {
    display: "block",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    flex: "1 0 0",
    overflow: "hidden",
    color: "var(--Primary-Black-One, #222)",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.112px",
  },
});

export const ResumeStatusChip = styled(Grid)({
  "&&": {
    display: "flex",
    height: "21px",
    padding: "0px 10px",
    alignItems: "center",
    borderRadius: "100px",
    background: "rgba(242, 175, 41, 0.14)",
  },
});

export const ResumeStatusChipText = styled(Typography)({
  "&&": {
    color: "#C0840C",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "147.023%",
  },
});

export const ResumeButton = styled(Button)(({ buttonType }) => ({
  "&&": {
    display: "flex",
    height: "30px",
    minWidth: "112px",
    width: "100%",
    gap: "6px",
    borderRadius: "100px",
    justifyContent: "center",
    alignItems: "center",

    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.055px",

    textTransform: "none",

    ...(buttonType == "view" && {
      color: "#222222",
      padding: "0px 24px",
      border: "1px solid var(--Primary-Black-One, #222)",
      background: "var(--Primary-White, #FFF)",
      fontWeight: 400,

      "&:hover": {
        background: "var(--Primary-White, #EFEFEF)",
      },
    }),

    ...(buttonType == "reupload" && {
      color: "#FFFFFF",
      padding: "0px 16px",
      background: "#353535",
      fontWeight: 700,

      "&:hover": {
        background: "#222222",
      },
    }),
  },
}));

export const StyledFormControl = styled(FormControl)({
  width: "100%",
});

export const TextFieldContainer = styled(Box)({
  "&&": {
    display: "flex",
    alignItems: "flex-start",
    gap: "8px",
    alignSelf: "stretch",

    "@media(max-width:767.9px)": {
      flexDirection: "column",
    },
  },
});

export const StyledErrorHelperText = styled(FormHelperText)({
  "&.MuiFormHelperText-root": {
    color: "#F75443",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.112px",

    margin: 0,
    marginLeft: "8px",
    marginTop: "4px",

    textWrap: "nowrap",
  },
});

export const StyledTextField = styled(TextField)(({ isError }) => ({
  width: "100%",

  "& .MuiInputBase-root": {
    display: "flex",
    minHeight: "48px",
    padding: "8px 16px",
    alignItems: "center",
    gap: "10px",
    flex: "1 0 0",
    borderRadius: "8px",
    border: !isError
      ? "1.5px solid var(--Grey-Grey, #D8D8D8)"
      : "1.5px solid #F75443",
    background: "#FFFFFF",

    "& .MuiInputBase-input": {
      height: "20px",
      padding: 0,
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0.112px",
      background: "var(--Primary-White, #FFF)",
      marginTop: "10px",
    },

    "&.MuiFilledInput-root::after": {
      display: "none",
    },

    "&.Mui-focused": {
      border: "1.5px solid #512ACC",
      color: "512ACC",
      background: "#FFFFFF",
    },

    "&:active": {
      background: "#FFFFFF",
    },

    "&:hover": {
      background: "#FFFFFF",
    },

    "&.MuiFilledInput-root::before": {
      display: "none",
    },

    "&.MuiFilledInput-root::hover": {
      background: "#FFFFFF",
    },
  },

  "& .MuiInputBase-root > input": {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px rgb(255, 255, 255) inset",
    },
  },

  "&.MuiFormControl-root > label": {
    marginLeft: "6px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },

  "&.MuiFormControl-root > label:focus": {
    fontSize: "100px",
  },

  "& .Mui-focused.MuiAutocomplete-input": {
    color: "red",
  },
}));

export const StyledErrorIcon = styled(ExclamationCircleIcon)({
  color: "#F75443",
  width: "20px",
  height: "20px",
});

export const StyledEyeIcon = styled(EyeIcon)(({ isError }) => ({
  color: isError && "#F75443",
  width: "24px",
  height: "24px",
  cursor: "pointer",
}));

export const StyledEyeSlashIcon = styled(EyeSlashIcon)(({ isError }) => ({
  color: isError && "#F75443",
  width: "24px",
  height: "24px",
  cursor: "pointer",
}));

export const CheckboxWrapper = styled(Grid)({
  "&&": {
    display: "flex",
    padding: "8px 16px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
  },
});

export const CheckboxContainer = styled(Grid)({
  "&&": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    alignSelf: "stretch",
  },
});

export const TalentSearchContainer = styled("label")({
  "&&": {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    alignSelf: "stretch",
  },
});

export const PDPAContainer = styled("label")({
  "&&": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
  },
});

export const StyledCheckbox = styled(Checkbox)(({ isError }) => ({
  "&&": {
    display: "flex",
    width: "32px",
    height: "32px",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "8px",
    border: isError
      ? "1px solid #F75443"
      : "1px solid var(--Primary-Black, #222)",

    "&.Mui-checked": {
      // Style for checked state
      background: "var(--Primary-Black, #222)",

      "& svg": {
        fill: "#222",
      },
    },

    "& svg": {
      fill: "#fff",
    },
  },
}));

export const StyledCheckIcon = styled(CheckIcon)({
  color: "#FFFFFF",
  width: "18px",
  height: "18px",
  flexShrink: 0,
});

export const CheckboxText = styled(Typography)(({ type }) => ({
  "&&": {
    display: "inline",

    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "italic",
    lineHeight: "20px",

    ...(type == "normal" && {
      color: "var(--Text-Light-Theme-Medium-Emphasis, rgba(0, 0, 0, 0.60))",
      fontWeight: 400,
    }),

    ...(type == "link" && {
      color: "var(--Primary-Hiredly-Purple, #512ACC)",
      fontWeight: 400,
      textDecorationLine: "underline",
      textDecorationStyle: "solid",
      textDecorationSkipInk: "none",
      textDecorationThickness: "auto",
      textUnderlineOffset: "auto",
      textUnderlinePosition: "from-font",

      cursor: "pointer",
    }),
  },
}));

export const DialogFooter = styled(Grid)({
  "&&": {
    display: "flex",
    padding: "16px 30px",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
    alignSelf: "stretch",
    borderTop: "1px solid var(--Grey-Grey, #D8D8D8)",
    background: "var(--Primary-White, #FFF)",
    width: "100%",
    height: "100%",
  },
});

export const ViewJobsButton = styled(Button)({
  "&&": {
    display: "flex",
    width: "160px",
    height: "36px",
    minWidth: "160px",
    padding: "0px 24px",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "100px",
    background: "var(--Primary-Black-Two, #353535)",

    textTransform: "none",

    color: "var(--Primary-White, #FFF)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",

    "&:hover": {
      background: "#222222",
    },
  },
});

export const StyledBackIcon = styled(ArrowRightIcon)({
  width: "20px",
  height: "20px",
  flexShrink: 0,
});

export const SocialMediaLoginContainer = styled(Grid)({
  "&&": {
    display: "flex",
    padding: "0px 16px 16px 16px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    alignSelf: "stretch",

    width: "100%",
  },
});

export const ContinueWithContainer = styled(Grid)({
  "&&": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",

    width: "100%",
  },
});

export const ContinueWithDivider = styled(Box)({
  "&&": {
    width: "100%",
    maxWidth: "255px",
    height: "1px",
    background: "rgba(0, 0, 0, 0.38)",
  },
});

export const ContinueWithText = styled(Typography)({
  "&&": {
    color: "#00000061",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.15px",

    textWrap: "nowrap",
  },
});

export const SocialMediaButtonContainer = styled(Grid)({
  "&&": {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "24px",
    alignSelf: "stretch",

    width: "100%",
  },
});

export const SocialMediaLoginButton = styled(Grid)({
  "&&": {
    display: "flex",
    width: "48px",
    height: "48px",
    padding: "15px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100px",
    border: "1px solid #000000",
    background: "Background/Background Grey",

    transition: "all 0.3s ease",

    "&:hover": {
      boxShadow: "2px 2px 0px 0px #222",
    },
  },
});

// Toast styles

export const ToastTitleContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

export const ToastTitle = styled(Typography)({
  color: "#000000",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "22px",
});

export const StyledErrorCircle = styled(SolidExclamationCircleIcon)({
  width: "15px",
  height: "15px",
  color: "#F75443",
});

export const ToastContentContainer = styled(Grid)({
  marginTop: "8px",
});

export const ToastContentText = styled(Typography)({
  color: "#00000099",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
});
