import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled as styling } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import Color from "../../../assets/colors";

export const Wrapper = styling(Grid)(({ theme }) => ({
  maxWidth: "1440px",
  padding: "0 100px",
  backgroundColor: Color.homepageGrey,

  [theme.breakpoints.down("tablet")]: {
    padding: "0 20px",
  },
}));

export const BannerHomepageWrapper = styling(Grid)((props) => ({
  backgroundColor: Color.homepageGrey,

  "@media (max-width: 1279px)": {
    marginTop: props.$alertBanner ? "126px" : "50px",
  },
  "@media (max-width: 640px)": {
    marginTop: props.$alertBanner ? "164px" : "64px",
  },
}));

export const ToastStyled = styling(ToastContainer)(({ state }) => ({
  "&.Toastify__toast-container": {
    width: "320px",
    "& .Toast-success": {
      borderLeft: `5px solid #90B938`,
      color: "#90B938",
    },

    "& .Toast-error": {
      borderLeft: `5px solid #F75443`,
      color: "#F75443",
    },
  },

  "& .Toastify__toast": {
    minHeight: "36px",
    borderRadius: "8px",
    backgroundColor: Color.white,
    boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.20)",
    padding: "8px 16px",

    "& .Toastify__close-button": {
      color: "#00000099",
      opacity: 1,
      position: "relative",
      padding: 0,
      marginTop: "10px",
    },

    "& .Toastify__progress-bar--wrp": {
      opacity: 0,
    },
  },
}));

export const ToastTitleContainer = styling(Grid)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

export const ToastTitle = styling(Typography)({
  color: "#000000",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "22px",
});

export const StyledCheckCircle = styling(CheckCircleIcon)({
  width: "15px",
  height: "15px",
  color: "#90B938",
});

export const ToastContentContainer = styling(Grid)({
  marginTop: "8px",
});

export const ToastContentText = styling(Typography)({
  color: "#00000099",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
});
