import { TitleContainer, TitleStyled } from "./styles";

function HomepageHeader() {
  return (
    <TitleContainer>
      <TitleStyled>
        Know <span style={{ fontStyle: "italic" }}>The Company</span>, Love{" "}
        <span style={{ fontStyle: "italic" }}>The Job</span>
      </TitleStyled>
    </TitleContainer>
  );
}
export default HomepageHeader;
