import { Box, Grid, InputAdornment } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import Cookies from "js-cookie";
import { isEmpty } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as config from "../../../config/config";
import {
  convertBase64,
  getItem,
  setItem,
} from "../../../helpers/data_management";
import {
  sendTrackingEvent,
  signupTracking,
} from "../../../helpers/tracking_management";
import {
  updateHomepageReplaceResumeDialog,
  updateHomepageUploadedResumeDialog,
  updateHomepageViewResumeDialog,
  updateJobAlertVisitorEmailDialog,
} from "../../../redux/actions/dialog_action";
import {
  checkAuthentication,
  getUser,
  globalLoginFunc,
  newResumeUpload,
  register,
  socialMediaLogin,
} from "../../../redux/actions/user_action";
import { store } from "../../../redux/stores/store";
import * as types from "../../../redux/types/user_type";
import {
  CheckboxContainer,
  CheckboxText,
  CheckboxWrapper,
  ContentTitle,
  ContinueWithContainer,
  ContinueWithDivider,
  ContinueWithText,
  DetailContainer,
  DetailResumeContainer,
  DetailWrapper,
  DialogContent,
  DialogFooter,
  DialogHeader,
  PDPAContainer,
  ResumeButton,
  ResumeButtonContainer,
  ResumeContainer,
  ResumeFilename,
  ResumeStatusChip,
  ResumeStatusChipText,
  ResumeStatusContainer,
  ResumeWrapper,
  SocialMediaButtonContainer,
  SocialMediaLoginButton,
  SocialMediaLoginContainer,
  StyledBackIcon,
  StyledCheckbox,
  StyledCheckCircleIcon,
  StyledCheckIcon,
  StyledCloseIcon,
  StyledDialog,
  StyledErrorCircle,
  StyledErrorHelperText,
  StyledErrorIcon,
  StyledEyeIcon,
  StyledEyeSlashIcon,
  StyledFormControl,
  StyledTextField,
  StyledTitle,
  StyledTitleInfo,
  TalentSearchContainer,
  TextFieldContainer,
  TitleContainer,
  ToastContentContainer,
  ToastContentText,
  ToastTitle,
  ToastTitleContainer,
  ViewJobsButton,
} from "./styles";
//import FacebookLoginButton from "../../homepage/FacebookLoginButton/FacebookLoginButton";

let socialButtonRefs = {};

export default function HomepageDropResume(props) {
  //const FacebookSocialMediaLoginButton = SocialLogin(FacebookLoginButton);

  const { userResume, setUserResume, triggerToast, inputFileRef } = props;

  const router = useRouter();

  const dispatch = useDispatch();

  const isLoggedIn = checkAuthentication();

  const dialogStatus = useSelector(
    (state) => state.dialog?.showHomepageUploadedResumeDialog
  );
  const signUpOrigin = useSelector((state) => state?.navbar?.signUpOrigin);
  const selectedJob = useSelector((state) => state?.jobs?.job);
  const selectedCompany = useSelector(
    (state) => state?.companies?.selectedCompany
  );

  const currentUrl = router.asPath;

  const [revealPassword, setRevealPassword] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    talentSearch: false,
    pdpa: false,
  });
  const [localUser, setLocalUser] = useState(null);
  const [nameFocus, setNameFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [nameError, setNameError] = useState({ error: false, message: "" });
  const [emailError, setEmailError] = useState({ error: false, message: "" });
  const [passwordError, setPasswordError] = useState({
    error: false,
    message: "",
  });
  const [pdpaError, setPdpaError] = useState(false);
  const [loginMethod, setLoginMethod] = useState("");
  const [key, setKey] = useState(1);

  let registering;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://connect.facebook.net/en_US/sdk.js";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    script.onload = () => {
      window.FB.init({
        appId: getAppId("Continue with Facebook"),
        autoLogAppEvents: true,
        xfbml: true,
        version: "v18.0",
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleClose = () => {
    setNameError({ error: false, message: "" });
    setEmailError({ error: false, message: "" });
    setPasswordError({ error: false, message: "" });
    setUser({
      name: "",
      email: "",
      password: "",
      talentSearch: false,
      pdpa: false,
    });

    dispatch(updateHomepageUploadedResumeDialog(false));

    if (!isEmpty(localUser?.resume)) {
      dispatch(updateHomepageReplaceResumeDialog(true));
    }
  };

  const handleViewResume = () => {
    dispatch(updateHomepageViewResumeDialog(true));
  };

  const handleReupload = () => {
    handleClose();

    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const getProvider = (ssoLogin) => {
    switch (ssoLogin) {
      case "Continue with Google": {
        return "google";
      }
      case "Continue with Linkedin": {
        return "facebook";
      }
      case "Continue with Facebook": {
        return "facebook";
      }
    }
  };

  function getAppId(ssoLogin) {
    switch (ssoLogin) {
      case "Continue with Google": {
        return process.env.GOOGLE_CLIENT_ID;
      }
      case "Continue with Linkedin": {
        return process.env.LINKEDIN_ID;
      }
      case "Continue with Facebook": {
        return process.env.FACEBOOK_APP_ID;
      }
    }
  }

  // Function to trigger job seekers who log in via email
  const triggerCustomEventEmailLogin = () => {
    sendTrackingEvent({ event: "email-login" });
  };

  // Function to trigger job seekers who log in via SSO
  const triggerCustomEventSsoLogin = (provider) => {
    sendTrackingEvent({
      event: `${provider == "google" ? "google-login" : "facebook-login"}`,
    });
  };

  // Function to trigger job seekers who sign up for the first time via email
  const triggerCustomEventFirstTimeEmailSignup = () => {
    sendTrackingEvent({
      event: "first-time-signup",
    });
  };

  // Function to trigger job seekers who sign up for the first time via SSO
  const triggerCustomEventSsoFirstTimeSignup = (provider) => {
    sendTrackingEvent({
      event: `${
        provider == "google"
          ? "google-sso-first-time-signup"
          : "facebook-sso-first-time-signup"
      }`,
    });
  };

  function handleRedirect() {
    const redirectTimeout = setTimeout(() => {
      router.push("/jobs");
    }, 2000);

    return () => {
      clearTimeout(redirectTimeout);
    };
  }

  function handleSwitch() {
    //setUser({ name: "", email: "", password: "", talentSearch: false, pdpa: false });
    setNameError({ error: false, message: "" });
    setEmailError({ error: false, message: "" });
    setPasswordError({ error: false, message: "" });
    setPdpaError(false);
    setIsLogin((prev) => !prev);
  }

  function inputValidation(type) {
    switch (type) {
      case "name":
        if (user?.name.length == 0) {
          setNameError({
            error: true,
            message: "Cannot be empty!",
          });
          return false;
        } else {
          if (/^[A-Za-z\s]+$/.test(user?.name)) {
            setNameError({
              error: false,
              message: "",
            });
            return true;
          } else {
            setNameError({
              error: true,
              message: "Do not include digit and symbol!",
            });
            return false;
          }
        }

      case "email":
        if (isEmpty(user?.email)) {
          setEmailError({
            error: true,
            message: "Cannot be empty!",
          });
          return false;
        } else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user?.email)) {
          setEmailError({
            error: false,
            message: "",
          });
          return true;
        } else {
          setEmailError({
            error: true,
            message: "Not email format!",
          });
          return false;
        }

      case "password":
        if (user?.password?.length >= 8) {
          setPasswordError({
            error: false,
            message: "",
          });
          return true;
        } else {
          setPasswordError({
            error: true,
            message: "Must be at least 8 characters!",
          });
          return false;
        }
      case "pdpa":
        if (user?.pdpa) {
          setPdpaError(false);
          return true;
        } else {
          setPdpaError(true);
          return false;
        }

      default:
        break;
    }
  }

  function resumeExistValidation(payload) {
    if (!isEmpty(payload?.resume)) {
      dispatch(updateHomepageReplaceResumeDialog(true));
      handleClose();
    } else {
      handleSubmitResume(userResume);
      handleClose();
      handleRedirect();
    }
  }

  const handleSignUpSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (
      !inputValidation("name") ||
      !inputValidation("email") ||
      !inputValidation("password") ||
      !inputValidation("pdpa")
    ) {
      return;
    }

    if (registering) return;

    registering = true;

    let urlParams = document.cookie
      .split("; ")
      .find((row) => row.startsWith("params="))
      ?.toString()
      .replace("params=", "");
    let mrpId = document.cookie
      .split("; ")
      .find((row) => row.startsWith("mrpId="))
      ?.toString()
      .replace("mrpId=", "");

    const { signupMedium, signupSource } = signupTracking(
      currentUrl,
      signUpOrigin,
      selectedJob,
      selectedCompany
    );

    let params = {
      name: user?.name,
      email: user?.email,
      password: user?.password,
      pdpaAccepted: user?.pdpa,
      urlParams: urlParams, // To track users who sign up from an advertisement
      referralToken: null, // To track users who sign up via Workanda campaign
      mrpParentId: mrpId, // To track referrer id for talent referral program
      signupMedium: signupMedium,
      signupSource: signupSource,
    };

    dispatch(register(params))
      .then((response) => {
        registering = false;

        if (response.type === types.REGISTER_ACCOUNT_SUCCEED) {
          let userId = response.user.id;
          let userName = response.user.name;
          let userEmail = response.user.email;

          triggerCustomEventFirstTimeEmailSignup();

          sendTrackingEvent({
            event: "CE_sign_up_drop_resume_homepage",
          });

          setItem("HOMEPAGE-RESUME-UPLOADED", true);
          resumeExistValidation(response?.payload?.user);

          // check if visitor job alert exists and store to local storage
          if (store.getState().jobs.visitorJobAlert) {
            setItem(
              "visitorJobAlert",
              store.getState().jobs.visitorJobAlert.toString()
            );
          }

          // check if visitor sign-up from job alert email mailer
          const visitorUrl = window.location.search;
          if (visitorUrl.includes("?visitor-sign-up")) {
            window.sessionStorage.setItem("visitorJobAlertSignUp", "true");
          }

          if (store.getState().user.isDropCV) {
            Cookies.set("userId", userId, { expires: 365 }); // Store user ID to cookies to be used by GA with 1 year expiry
            Cookies.set("isRegistered", userId ? true : false, {
              expires: 365,
            }); // Check if user is registered/logged in and store cookie for CleverTap
            router.reload();
          } else {
            try {
              Cookies.set("userId", userId, { expires: 365 }); // Store user ID to cookies to be used by GA with 1 year expiry
              Cookies.set("isRegistered", userId ? true : false, {
                expires: 365,
              }); // Check if user is registered/logged in and store cookie for CleverTap
            } catch (error) {
              //
            }

            handleClose();
            handleRedirect();
          }
        } else if (response.message == "account exist") {
          triggerToast(false, toastContent("Account already exists!"));
        }
        props.updateJobAlertVisitorEmailDialog(false);
      })
      .catch((error) => {
        registering = false;
      });
  };

  const handleLoginSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!inputValidation("email") || !inputValidation("password")) {
      return;
    }

    let params = {
      canCancel: true,
      email: user?.email,
      password: user?.password,
    };

    dispatch(globalLoginFunc(params)).then(async (response) => {
      let userId = response?.payload?.user?.id;

      if (response.type === types.NEW_SESSION_LOGIN_SUCCEED) {
        setUser({
          ...user,
          name: "",
          email: "",
          password: "",
          talentSearch: false,
          pdpa: false,
        });
        setItem("HOMEPAGE-RESUME-UPLOADED", true);
        resumeExistValidation(response?.payload?.user);
        Cookies.set("userId", userId, { expires: 365 }); // Store user ID to cookies to be used by GA with 1 year expiry
        Cookies.set("isRegistered", userId ? true : false, { expires: 365 }); // Check if user is registered/logged in and store cookie for CleverTap

        triggerCustomEventEmailLogin();
        sendTrackingEvent({
          event: "CE_login_drop_resume_homepage",
          method: loginMethod,
        });

        // check if visitor job alert exists and store to local storage
        if (store.getState().jobs.visitorJobAlert) {
          setItem(
            "visitorJobAlert",
            store.getState().jobs.visitorJobAlert.toString()
          );
        }

        localStorage.setItem("job_preference_clicked", 0);

        const emailHotJob = getItem("email-hot-job");

        if (emailHotJob) {
          localStorage.removeItem("email-hot-job");
          return router.push("/profile#for-you");
        }

        // handleRedirect();
      } else if (
        response.type === types.SESSION_LOGIN_FAILED &&
        response.invalid
      ) {
        triggerToast(false, toastContent("Invalid email or password."));
      } else {
        triggerToast(
          false,
          toastContent("Failed to login at the moment, please try again later.")
        );
      }
      updateJobAlertVisitorEmailDialog(false);
    });
  };

  const handleSocialLogin = (response) => {
    let provider = response?.authResponse?.graphDomain || response?._provider;

    // To track users who sign up from an advertisement
    let urlParams = document.cookie
      .split("; ")
      .find((row) => row.startsWith("params="))
      ?.toString()
      .replace("params=", "");

    let params = {
      provider: provider,
      accessToken: response?.authResponse?.accessToken || response?._token?.accessToken,
      urlParams: urlParams,
    };

    dispatch(socialMediaLogin(params)).then((response) => {
      try {
        if (socialButtonRefs[provider]) {
          socialButtonRefs[provider].props.triggerLogout();
        }
      } catch (error) {}

      if (
        response.type === types.SOCIAL_LOGIN_FAILED &&
        response.payload?.noEmail === true
      ) {
        triggerToast(false, toastContent("Login failed."));
      }

      if (response.type === types.SOCIAL_LOGIN_SUCCEED) {
        let userId = response.payload.user.id;
        let userName = response.payload.user.name;
        let userEmail = response.payload.user.email;

        setItem("HOMEPAGE-RESUME-UPLOADED", true);
        resumeExistValidation(response?.payload?.user);

        sendTrackingEvent({
          event: "CE_login_drop_resume_homepage",
          method: loginMethod,
        });

        if (response?.payload?.newUser === true) {
          triggerCustomEventSsoFirstTimeSignup(provider);
        }

        // Check if visitor job alert exists and store to local storage
        if (store.getState().jobs.visitorJobAlert) {
          setItem(
            "visitorJobAlert",
            store.getState().jobs.visitorJobAlert.toString()
          );
        }

        Cookies.set("userId", userId, { expires: 365 }); // Store user ID to cookies to be used by GA with 1 year expiry
        Cookies.set("isRegistered", userId ? true : false, { expires: 365 }); // Check if user is registered/logged in and store cookie for CleverTap

        localStorage.setItem("job_preference_clicked", 0);

        triggerCustomEventSsoLogin(provider);

        if (
          response.payload.user.resume === "" &&
          !response.payload.user.onboardingComplete
        ) {
          handleClose();
          return dispatch(updateResumeConfirmationDialog(true));
        }

        const emailHotJob = getItem("email-hot-job");

        if (emailHotJob) {
          localStorage.removeItem("email-hot-job");
          return router.push("/profile#for-you");
        }
      }
      updateJobAlertVisitorEmailDialog(false);
    });
  };

  const handleSocialLoginFailure = (error) => {};

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const params = {
        _provider: "google",
        _token: {
          accessToken: tokenResponse.access_token,
        },
      };
      handleSocialLogin(params);
    },
    onError: (errorResponse) => onFailure(errorResponse),
  });

  const handleLinkedInButtonClick = () => {
    let oauthUrl = new URL("https://www.linkedin.com/oauth/v2/authorization");
    oauthUrl.searchParams.append("response_type", "code");
    oauthUrl.searchParams.append("client_id", process.env.LINKEDIN_ID);
    oauthUrl.searchParams.append(
      "redirect_uri",
      process.env.LINKEDIN_REDIRECT_URI
    );
    oauthUrl.searchParams.append("scope", process.env.LINKEDIN_SCOPE);

    var width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;

    window.location = oauthUrl;

    // check if visitor job alert exists and store to local storage
    if (store.getState().jobs.visitorJobAlert) {
      setItem(
        "visitorJobAlert",
        store.getState().jobs.visitorJobAlert.toString()
      );
    }
    updateJobAlertVisitorEmailDialog(false);

    convertBase64(userResume).then((cvFile) => {
      setItem("TEMP_RESUME_BASE64", JSON.stringify(cvFile));
    });
  };

  async function handleSubmitResume(userResume) {
    if (userResume) {
      convertBase64(userResume).then((cvFile) => {
        // Trigger graphQL to upload cvFile
        let params = {
          resume: cvFile,
          resume_drop: true,
          canCancel: false,
          user_resume: "",
          origin: "homepage",
          autoGenerated: false,
        };

        dispatch(newResumeUpload(params)).then((response) => {
          if (response.type === types.UPDATE_USER_CV_SUCCEED) {
            sessionStorage.setItem("HOMEPAGE-RESUME-UPLOADED", true);
            setItem(types.USER_PROFILE, JSON.stringify(response.user));
            handleRedirect();
          } else {
            triggerToast(
              false,
              toastContent("Failed to update resume, please try again later.")
            );
          }
        });
      });
    }
  }

  function toastContent(message) {
    return (
      <Grid>
        <ToastTitleContainer>
          <StyledErrorCircle />
          <ToastTitle>Error</ToastTitle>
        </ToastTitleContainer>
        <ToastContentContainer>
          <ToastContentText>{message}</ToastContentText>
        </ToastContentContainer>
      </Grid>
    );
  }

  const FacebookLoginButton = ({ children, triggerLogin, ...props }) => {
    return (
      <SocialMediaLoginButton
        sx={{ background: "#1877F2" }}
        onClick={triggerLogin}
        {...props}
      >
        {children}
      </SocialMediaLoginButton>
    );
  };

  const useSocialLogin = ({
    provider,
    appId,
    onLoginSuccess,
    onLoginFailure,
  }) => {
    const handleLogin = () => {
      if (provider === "facebook") {
        setLoginMethod("facebook");
        window.FB.login(
          (response) => {
            if (response.authResponse) {
              triggerCustomEventSsoLogin(provider);
              onLoginSuccess(response);
            } else {
              onLoginFailure(response);
            }
          },
          { scope: "public_profile,email" }
        );
      }
    };
    return { handleLogin };
  };

  const FacebookSocialMediaLoginButton = (props) => {
    const { handleLogin } = useSocialLogin({
      provider: props.provider,
      appId: props.appId,
      onLoginSuccess: props.onLoginSuccess,
      onLoginFailure: props.onLoginFailure,
    });

    return (
      <FacebookLoginButton triggerLogin={handleLogin} {...props}>
        {props.children}
      </FacebookLoginButton>
    );
  };

  return (
    <>
      <StyledDialog open={dialogStatus}>
        <Box
          width={"100%"}
          component="form"
          onSubmit={(e) => {
            e.preventDefault();

            if (isLogin) handleLoginSubmit(e);
            else handleSignUpSubmit(e);
          }}
        >
          <DialogHeader>
            <Grid display={"flex"} width={"100%"}>
              <TitleContainer>
                <StyledCheckCircleIcon />
                <StyledTitle>Your resume is uploaded 🎉</StyledTitle>
              </TitleContainer>
              <StyledCloseIcon onClick={handleClose} />
            </Grid>
            <Grid display={"flex"} width={"100%"}>
              <StyledTitleInfo>
                {!isLogin
                  ? "Create an account to view your job matches! Have an account? "
                  : "Login to view your job matches! Don’t have an account? "}
                <StyledTitleInfo type={"login"} onClick={handleSwitch}>
                  {!isLogin ? "Log In" : "Sign Up"}
                </StyledTitleInfo>
              </StyledTitleInfo>
            </Grid>
          </DialogHeader>
          <DialogContent>
            <DetailWrapper isLogin={isLogin}>
              <DetailContainer>
                <DetailResumeContainer>
                  <ContentTitle>Your Details</ContentTitle>
                  <ResumeWrapper>
                    <ResumeContainer>
                      <ResumeStatusContainer>
                        <ResumeFilename>{userResume?.name}</ResumeFilename>
                        <ResumeStatusChip>
                          <ResumeStatusChipText>In Review</ResumeStatusChipText>
                        </ResumeStatusChip>
                      </ResumeStatusContainer>
                      <ResumeButtonContainer>
                        <ResumeButton
                          onClick={handleViewResume}
                          type="button"
                          buttonType={"view"}
                        >
                          View
                        </ResumeButton>
                        <ResumeButton
                          onClick={handleReupload}
                          type="button"
                          buttonType={"reupload"}
                        >
                          Reupload
                        </ResumeButton>
                      </ResumeButtonContainer>
                    </ResumeContainer>
                  </ResumeWrapper>
                </DetailResumeContainer>
                <TextFieldContainer>
                  {!isLogin && (
                    <StyledFormControl>
                      <StyledTextField
                        value={user?.name}
                        onFocus={() => {
                          setNameFocus(true);
                          setNameError({
                            ...nameError,
                            error: false,
                          });
                        }}
                        onBlur={() => {
                          inputValidation("name");
                          if (user?.name.length == 0) {
                            setNameFocus(false);
                          }
                        }}
                        onChange={(e) => {
                          setUser((prev) => ({
                            ...prev,
                            name: e?.target?.value,
                          }));
                        }}
                        label={nameFocus ? "Name" : "Name*"}
                        variant="filled"
                        isError={nameError?.error}
                        InputProps={{
                          endAdornment: nameError?.error && (
                            <InputAdornment position="end">
                              <StyledErrorIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {nameError?.error && (
                        <StyledErrorHelperText>
                          {nameError?.message}
                        </StyledErrorHelperText>
                      )}
                    </StyledFormControl>
                  )}
                  <StyledFormControl>
                    <StyledTextField
                      value={user?.email}
                      onFocus={() => {
                        setEmailFocus(true);
                        setEmailError({
                          ...emailError,
                          error: false,
                        });
                      }}
                      onBlur={() => {
                        inputValidation("email");
                        if (user?.email.length == 0) {
                          setEmailFocus(false);
                        }
                      }}
                      onChange={(e) => {
                        setUser((prev) => ({
                          ...prev,
                          email: e?.target?.value,
                        }));
                      }}
                      label={emailFocus ? "Email" : "Email*"}
                      variant="filled"
                      type="email"
                      isError={emailError?.error}
                      InputProps={{
                        endAdornment: emailError?.error && (
                          <InputAdornment position="end">
                            <StyledErrorIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {emailError?.error && (
                      <StyledErrorHelperText>
                        {emailError?.message}
                      </StyledErrorHelperText>
                    )}
                  </StyledFormControl>
                  <StyledFormControl>
                    <StyledTextField
                      value={user?.password}
                      onFocus={() => {
                        setPasswordFocus(true);
                        setPasswordError({
                          ...passwordError,
                          error: false,
                        });
                      }}
                      onBlur={() => {
                        inputValidation("password");
                        if (user?.password.length == 0) {
                          setPasswordFocus(false);
                        }
                      }}
                      onChange={(e) => {
                        setUser((prev) => ({
                          ...prev,
                          password: e?.target?.value,
                        }));

                        if (user?.password.length > 0) {
                          setPasswordFocus(true);
                        }
                      }}
                      label={passwordFocus ? "Password" : "Password*"}
                      variant="filled"
                      type={revealPassword ? "text" : "password"}
                      isError={passwordError?.error}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => {
                              setRevealPassword((prev) => !prev);
                            }}
                          >
                            {revealPassword ? (
                              <StyledEyeIcon isError={passwordError?.error} />
                            ) : (
                              <StyledEyeSlashIcon
                                isError={passwordError?.error}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    {passwordError?.error && (
                      <StyledErrorHelperText>
                        {passwordError?.message}
                      </StyledErrorHelperText>
                    )}
                  </StyledFormControl>
                </TextFieldContainer>
              </DetailContainer>
            </DetailWrapper>
            {!isLogin ? (
              <CheckboxWrapper>
                <CheckboxContainer>
                  <TalentSearchContainer>
                    <StyledCheckbox
                      checked={user?.talentSearch}
                      onChange={(e) => {
                        setUser((prev) => ({
                          ...prev,
                          talentSearch: !prev?.talentSearch,
                        }));
                      }}
                      checkedIcon={<StyledCheckIcon />}
                    />
                    <CheckboxText type={"normal"}>
                      Allow employers to discover my resume for more
                      opportunities!
                    </CheckboxText>
                  </TalentSearchContainer>
                  <PDPAContainer>
                    <StyledCheckbox
                      checked={user?.pdpa}
                      isError={pdpaError}
                      onChange={(e) => {
                        setUser((prev) => ({
                          ...prev,
                          pdpa: !prev?.pdpa,
                        }));
                        if (e?.target?.checked) {
                          setPdpaError(false);
                        } else {
                          setPdpaError(true);
                        }
                      }}
                      checkedIcon={<StyledCheckIcon />}
                    />
                    <Grid>
                      <CheckboxText type={"normal"}>
                        I accept Hiredly’s
                      </CheckboxText>
                      &nbsp;
                      <CheckboxText type={"link"}>
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "currentcolor",
                          }}
                          href={"/privacy-policy"}
                        >
                          Privacy Policy
                        </Link>
                      </CheckboxText>
                      &nbsp;
                      <CheckboxText type={"normal"}>and the</CheckboxText>&nbsp;
                      <CheckboxText type={"link"}>
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "currentcolor",
                          }}
                          href={"/terms-and-conditions"}
                        >
                          Terms of Conditions
                        </Link>
                      </CheckboxText>
                    </Grid>
                  </PDPAContainer>
                </CheckboxContainer>
              </CheckboxWrapper>
            ) : (
              <SocialMediaLoginContainer>
                <ContinueWithContainer>
                  <ContinueWithDivider />
                  <ContinueWithText>Or continue with</ContinueWithText>
                  <ContinueWithDivider />
                </ContinueWithContainer>
                <SocialMediaButtonContainer>
                  <SocialMediaLoginButton
                    onClick={() => {
                      setLoginMethod("google");
                      googleLogin();
                    }}
                  >
                    <Box
                      sx={{ width: "100%", height: "100%" }}
                      component={"img"}
                      src={
                        config.assetDomain +
                        "/images/hiredly/google-logo-login.svg"
                      }
                    />
                  </SocialMediaLoginButton>
                  <SocialMediaLoginButton
                    sx={{ background: "#1976D2" }}
                    onClick={() => {
                      handleLinkedInButtonClick();
                    }}
                  >
                    <Box
                      sx={{ width: "100%", height: "100%" }}
                      component={"img"}
                      src={
                        config.assetDomain +
                        "/images/hiredly/linkedin-logo-login.svg"
                      }
                    />
                  </SocialMediaLoginButton>
                  <FacebookSocialMediaLoginButton
                    getInstance={(node) => {
                      if (node) {
                        if (
                          socialButtonRefs[
                            getProvider("Continue with Facebook")
                          ] == undefined
                        ) {
                          socialButtonRefs[
                            getProvider("Continue with Facebook")
                          ] = node;
                        }
                      }
                    }}
                    provider={getProvider("Continue with Facebook")}
                    appId={getAppId("Continue with Facebook")}
                    onLoginSuccess={handleSocialLogin}
                    onLoginFailure={handleSocialLoginFailure}
                  >
                    <Box
                      sx={{ width: "100%", height: "100%" }}
                      component={"img"}
                      src={
                        config.assetDomain +
                        "/images/hiredly/facebook-logo-login.svg"
                      }
                    />
                  </FacebookSocialMediaLoginButton>
                </SocialMediaButtonContainer>
              </SocialMediaLoginContainer>
            )}
          </DialogContent>
          <DialogFooter>
            <ViewJobsButton
              type="submit"
              onClick={() => {
                setLoginMethod("email");
              }}
            >
              View Jobs! <StyledBackIcon />
            </ViewJobsButton>
          </DialogFooter>
        </Box>
      </StyledDialog>
    </>
  );
}
