import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/styles";
import Color from "../../../../assets/colors";

// Typography
export const TitleContainer = styling(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const TitleStyled = styling(Typography)((props) => ({
  "&&": {
    fontFamily: "Georgia",
    color: Color.textHighEmphasis,
    fontStyle: props.italic ? "italic" : "normal",
    lineHeight: "1.1",
    fontSize: "56px",
    textAlign: "center",
    width: "100%",
    letterSpacing: "-0.28px",

    [props.theme.breakpoints.between(1280, 1440)]: {
      fontSize: "48px",
    },

    [props.theme.breakpoints.down("sm")]: {
      fontSize: "35px",
    },
  },
}));
