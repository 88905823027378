import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

const uploadFileToS3 = async (file) => {
  const S3_BUCKET = "assets.wobbjobs.resume.com";
  const REGION = "ap-southeast-1";

  // S3 Client Setup
  const s3Client = new S3Client({
    region: REGION,
    credentials: {
      accessKeyId: process.env.NEXT_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.NEXT_S3_SECRET_ACCESS_KEY,
    },
  });

  // Format file name (replace spaces, extract extension)
  const formatFileName = file?.name.replace(/\s+/g, "_");
  const extension = formatFileName?.split(".").pop();
  const baseFileName = formatFileName?.replace(`.${extension}`, "");

  // Generate unique filename with timestamp
  const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
  const fileNameWithTimestamp = `${timestamp}_${baseFileName}.${extension}`;

  // Upload file to S3
  const uploadParams = {
    Bucket: S3_BUCKET,
    Key: fileNameWithTimestamp,
    Body: file,
  };

  try {
    await s3Client.send(new PutObjectCommand(uploadParams));

    // Generate a public URL
    const fileURL = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${fileNameWithTimestamp}`;
    return fileURL;
  } catch (err) {
    console.error("Error uploading file:", err);
    return null; // Return null on failure
  }
};

export { uploadFileToS3 };
