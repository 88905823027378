import { Dialog } from "@mui/material";
import { styled } from "@mui/styles";

export const StyledDialog = styled(Dialog)({
  "& .MuiDialog-container .MuiPaper-root": {
    width: "90vw",
    height: "90vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
  },
});
